html {
  overflow-y: auto;
  overflow-x: hidden
}

body {
  min-height: 100% !important;
  height: unset !important;
  min-width: 100vw;
}

#root {
  width: 100%;
  min-height: 100vh;
  height: 100% !important;
  min-width: 320px;
  overflow-x: hidden !important;
}

a, a * {
  cursor: pointer;
}

.center {
  text-align: center;
}

.email-verification {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
  color: #9cb3c9;
  font-size: 13px;
  font-weight: bold;
  background: black;
  text-align: center;
}

.email-verification .button {
  margin: 0 20px;
  padding: 6px 7px;
  font-weight: 600;
}

.entities-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.entities-wrapper .entity-label.ui.basic.label,
.entity-label.ui.basic.label {
  border: 1px solid #1f364d;
  border-radius: 3px;
  background-color: transparent;
  position: relative;
  color: white;
  padding: 8px 18px;
  margin: 5px 10px 5px 0;
  font-weight: 700;
}


.entities-wrapper .entity-label.ui.basic.label:hover,
.entity-label.ui.basic.label:hover {
  border-color: #4799eb;
}

.entities-wrapper .entity-label.ui.basic.label .remove span,
.entity-label.ui.basic.label .remove span {
    width: 20px;
    height: 20px;
    position: absolute;
    color: white;
    background: #1f364d;
    border-radius: 99999px;
    top: -10px;
    right: -10px;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.entities-wrapper .entity-label.ui.label.basic .remove span:hover,
.entity-label.ui.label.basic .remove span:hover {
  cursor: pointer;
}

.entities-wrapper .entity-label.ui.basic.label:hover .remove span,
.entity-label.ui.basic.label:hover .remove span {
  background-color: #4799eb;
  /* color: #1f364d; */
}

.ui.search.swa .input {
  width: 250px;
  max-width: 100%
}

.location-form input,
.ui.search.swa .prompt {
  border-radius: 3px;
  color: white;
}

.location-form input:focus,
.ui.search.swa .prompt:focus {
  border-color: #85b7d9;
  box-shadow: none;
  outline: 0 none;
}

.location-form .results,
.ui.search.swa .results {
  background-color: #0e2439;
  border-color: #1f364d;
  border-radius: .28571429rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  width: 250px;
  max-width: 100%;
  z-index: 9999999999;
}

.location-form .results .message.empty .header *,
.ui.search.swa .results .message.empty .header * {
  font-size: 14px;
  font-weight: 500;
}

.location-form .result,
.ui.search.swa .result {
  padding: 9.692307692307692px 12.923076923076923px;
}

.location-form .result:hover,
.location-form .result:active,
.ui.search.swa .result:hover,
.ui.search.swa .result.active {
  background-color: #4799eb;
}

.location-form .menu-item-swa-wrapper,
.ui.search.swa .menu-item-swa-wrapper {
  /* border: 3px solid #1f364d; */
  /* padding: 5px; */
  color: white;
}

.location-form .menu-item-swa-wrapper .name,
.ui.search.swa .menu-item-swa-wrapper .name {
  font-size: 14px;
  line-height: 18.307692307692307px;
}

.location-form .menu-item-swa-wrapper .count,
.ui.search.swa .menu-item-swa-wrapper .count {
  font-size: 10.76923076923077px;
  line-height: 1;
}

.ui.search.swa .result .swa-addition-wrapper {
  margin: -.85714286em -1.14285714em;
  padding: .85714286em 1.14285714em;
  background-color: #0e2439;
}

.location-form .result {
  padding: 12px 16px;
}

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 100;
  background-color: #0e2439;
}

.reference .ui.search.dropdown > input.search,
.heading .control .ui.search.swa .ui.input > input,
.onboarding-form-wrapper.withoutbg input {
  border-color: #1f364d !important;
  color: white;
}

.heading .control .ui.search.swa .ui.input > input:focus {
  border-color: #4799eb;
}

.ui.dropdown .menu .selected.item:hover {
  background-color: #4799eb;
}

.ui.secondary.button {
  border: 3px solid #274562;
  padding-top: 7px;
  padding-bottom: 7px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0,0,0,.1);
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0,0,0,.25);
  -webkit-transition: color .2s ease;
  transition: color .2s ease;
}

.page-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #0e2439;
  z-index: 9999;
}

@media (max-width: 767px) {
  .tablet-and-up {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .mobile-only {
    display: none !important;
  }
}

@media (max-width: 1023px) {
  .mobile-tablet-only {
    display: none !important;
  }
}

.react-phone-number-input {
  position: relative;
}

.ui.form input.react-phone-number-input__input {
  height: unset !important;
  padding-left: 50px;  
}

.react-phone-number-input__icon--international,
.react-phone-number-input__country-select-arrow {
  color:white;
}

.react-phone-number-input__country-select {
  color: black !important;
}

.react-phone-number-input__country {
  position: absolute !important;
  top:11px;
  left: 10px;
  border: none;
}

.react-phone-number-input__icon {
  display: grid;
  align-items: center;
}

.checkbox-with-link {
  display: flex;
}

.checkbox-with-link .checkbox {
  margin-right: 8px;
}

.checkbox-with-link a {
  text-decoration: underline;
}

.checkbox-with-link .ui.fitted.checkbox::after {
  display: none;
}


.bubbleInput.entity-label.ui.basic.label {
  font-size: 1em;
  padding: calc(.60714286em - 3px) 1em;
  margin: 0;
  border: .5px solid #0e2439;
  border-radius: .25px;
  box-sizing: border-box;
  line-height: 1.21428571em;
  /* position: absolute;
  transform: scale(.8);
  top: -2px;
  background-color: #233b53;
   */
}

.bubbleInput.entity-label.ui.basic.label:hover {
  border-color: #4799eb;
}

textarea {
  background: 0 0;
  border: .5px solid #0e2439;
  border-radius: .25px;
  outline: 0;
  color: #fff;
  border-radius: .14285714rem;
}
