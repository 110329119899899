@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 200;
    src: local('Source Sans Pro ExtraLight'),local('SourceSansPro-ExtraLight'),url('/fonts/sourcesanspro/6xKydSBYKcSV-LCoeQqfX1RYOo3i94_wlxdr.ttf') format('truetype')
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    src: local('Source Sans Pro Light'),local('SourceSansPro-Light'),url('/fonts/sourcesanspro/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdr.ttf') format('truetype')
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: local('Source Sans Pro Regular'),local('SourceSansPro-Regular'),url('/fonts/sourcesanspro/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7g.ttf') format('truetype')
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: local('Source Sans Pro SemiBold'),local('SourceSansPro-SemiBold'),url('/fonts/sourcesanspro/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlxdr.ttf') format('truetype')
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: local('Source Sans Pro Bold'),local('SourceSansPro-Bold'),url('/fonts/sourcesanspro/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwlxdr.ttf') format('truetype')
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 900;
    src: local('Source Sans Pro Black'),local('SourceSansPro-Black'),url('/fonts/sourcesanspro/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nwlxdr.ttf') format('truetype')
}

@font-face {
  font-family: Gibson;
  src: url("/fonts/gibson/Gibson.ttf") format("truetype");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("/fonts/helvetica/HelveticaNeue.ttf") format("truetype");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: "Consolas";
  src: url("/fonts/consolas/Consolas.ttf") format("truetype");
  font-weight: 400;
  font-style: normal
}