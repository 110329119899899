.ui.container.legal-page {
  max-width: 750px !important;
  margin-top: 36px;
  margin-bottom: 36px;
}

.legal-page h1 {
  margin-bottom: 36px;
  font-size: 30px;
}

.legal-page h2 {
  margin-top: 27px;
  font-size: 20px;
}

.legal-page p, .legal-page ul {
  color: #9cb3c9;
  font-size: 18px;
  line-height: 1.4em;
}

.legal-page ul {
  padding-inline-start: 20px;
}


.legal-page p:not(:first-child) {
  margin-top: 15px;
}

.legal-page a {
  color: white;
}

.legal-page ol li {
  font-size: 17px;
  line-height: 26px;
  text-decoration: underline;
  list-style-type: none;
}

.legal-page ol li a:hover {
  font-weight: 600
}

.legal-page p a:hover {
  text-decoration: underline;
}

.navbar.legal-nav .ui.container {
  max-width: 750px !important;
}
