.personal-details {
  display: grid;
  grid-template-columns: 245px auto 1fr;
  grid-template-areas:
    "image header ."
    "image bio    ."
  ;
  padding: 10px;
  margin: 50px 0 20px;
}

.personal-details .image {
  grid-area: image;
  object-fit: cover;
}

.personal-details .header {
  grid-area: header;
  display: flex;
  /* justify-content: space-between; */
  margin: auto 0;
}

.personal-details .header .name {
  font-size: 30px;
  margin: auto 0;
  display: inline-flex;
}

.personal-details .tick {
  height: 25px;
  width: 25px;
  margin: auto 0 auto 
}

.personal-details .header .button {
  margin: auto 0 auto 10px;
}

.personal-details .bio {
  grid-area: bio;
}

.personal-details .bio > * {
  margin: 10px 0;
  font-size: 16px
}

.personal-details .bio .bottom > * {
  margin: 10px 0;
}

.personal-details .bio .bottom .social a {
  font-size: 18px;
  color: #9cb3c9;
  margin-right: 10px;
}

.personal-details .bio .bottom .social a:hover {
  color: #4799eb;
}


.profile .section {
  margin: 20px 0;
}

.profile .section .heading {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.profile .section .heading .title {
  font-size: 24px;
  margin: auto 20px auto 0;
}

.profile .section .heading .control .search i {
  display: none;
}

.profile .section .heading .control > * {
  margin: 5px 20px 5px 0;
}

.profile .section .draggable-card { 
  margin-bottom: 1em;
}

.profile .experience-wrapper,
.profile .education-wrapper {
  margin: -4px;
}

.profile .experience-wrapper .wrapper,
.profile .education-wrapper .wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.profile .experience-wrapper .header,
.profile .education-wrapper .header {
  display: flex;
}

.profile .experience-wrapper .header .name a,
.profile .education-wrapper .header .details > .name a {
  font-size: 18px;
  color: #b9c9da;
}

.profile .experience-wrapper .header > *,
.profile .experience-wrapper .actions,
.profile .education-wrapper .header > *,
.profile .education-wrapper .actions {
  margin: auto 0;
}

.profile .new-experience .image,
.profile .experience-wrapper .image,
.profile .new-education .image,
.profile .education-wrapper .image {
  margin-right: 20px;
  object-fit: cover;
}

/* .profile .experience-wrapper .info,
.profile .education-wrapper .info {
  display: flex;
} */

.profile .experience-wrapper .title,
.profile .education-wrapper .programme {
  font-size: 15px;
  font-weight: 600;
  color: white;
  margin-top: 5px;
}

.profile .experience-wrapper .title,
.profile .education-wrapper .programme {
  display: flex;
  margin-right: 10px;
}


.profile .experience-wrapper .details,
.profile .education-wrapper .details {
  margin-right: 20px;
}

.profile .experience-wrapper .dates,
.profile .education-wrapper .dates {
  margin-top: 5px;
}

.profile .experience-wrapper .description,
.profile .education-wrapper .description {
  margin-top: 5px;
}

.profile .experience-wrapper .actions,
.profile .education-wrapper .actions {
  display: flex;
  align-items: center;
}

/* .profile .experience-wrapper .actions .first,
.profile .education-wrapper .actions .first {
  margin-right: 25px;
} */

.profile .date-divider {
  margin: 0 5px;
}

.profile .new-experience,
.profile .new-education {
  margin: -4px;
  display: flex;
}


.profile .new-experience .form,
.profile .new-education .form {
  display: grid;
  max-width: 100%;
  width: 100%;
}

.profile .new-experience .swa.search .ui.input,
.profile .new-education .swa.search .ui.input {
  width: 100%;
}

.profile .new-experience .dates,
.profile .new-education .programme,
.profile .new-education .dates {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 10px 0;
}

.profile .new-education .programme {
  margin-top: 0;
}

.profile .new-experience .description,
.profile .new-education .description {
  margin-bottom: 10px;
}

.profile .new-experience .search.dropdown,
.profile .new-experience .input,
.profile .new-education .search.dropdown,
.profile .new-education .input {
  width: 100%;
  min-width: 0;
  /* color: #000000f2; */
}

.profile .new-experience .search.dropdown input,
.profile .new-experience .input input,
.profile .new-experience textarea,
.profile .new-education .search.dropdown input,
.profile .new-education .input input,
.profile .new-education textarea {
  /* color: #000000f2; */
}

.profile .new-experience .search.dropdown .text,
.profile .new-education .search.dropdown .text {
  white-space: nowrap;
}

.profile .new-experience .dates .start,
.profile .new-education .dates .start,
.profile .new-education .programme .type {
  margin-right: 10px;
  /* color: #000000f2; */
}

.profile .new-experience .dates .start > *,
.profile .new-experience .dates .end > * {
  margin-bottom: 10px
}

.profile .new-experience .dates .present-date {
  font-size: 16px;
  color: white;
  margin: auto 0;
}

.profile .new-experience .job-title {
  margin-bottom: 10px;
}

.profile .new-experience .dates .ui.checkbox {
  grid-column: 1/3;
}

.profile .new-experience .dates .ui.checkbox label {
  color: white;
}

.profile .new-experience .save,
.profile .new-education .save {
  margin-right: 10px;
}

@media (max-width: 767px) {
  .personal-details {
    grid-template-columns: 95px auto;
    grid-template-areas:
      "image header"
      "bio   bio   "
    ;
  }

  .personal-details .image {
    width: 75px;
    height: 75px;
    margin-right: 20px;
  }

  .personal-details .tick {
    width: 20px;
    height: 20px;
  }

  .personal-details .header {
    display: block;
    width: fit-content;
  }

  .personal-details .header .name {
    font-size: 20px;
  }

  .personal-details .header .button:first-child {
    margin: 10px 0 auto 0;
  }

  .personal-details .bio > * {
    margin: 10px 0;
    font-size: 14px
  }

  .profile .new-experience,
  .profile .new-education {
    flex-wrap: wrap;
  }

  .profile .new-experience .image,
  .profile .new-education .image {
    margin-bottom: 10px;
  }

  .profile .experience-wrapper .wrapper,
  .profile .education-wrapper .wrapper {
    display: block;
  }

  .profile .experience-wrapper .actions,
  .profile .education-wrapper .actions {
    margin-top: 5px;
  }

}
