.landing-page-container {
  /* background-color: #0e2439;
  background-size: 97.5%;
  background-repeat: no-repeat;
  background-position: center; */
  width: 100%;
  height: 100%;
  overflow: auto;
  color: white;
}

/* #bg-video {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: calc(100% - 45px);
} */

.landing-page-bg {
  transition: background-image 20000ms ease-in-out;
  background-color: #0e2439;
  background-blend-mode: multiply;
  background-size: cover;
  background-position-x: 90%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
}



.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  height: 100%;
}

.slide *, .lpnav *:not(i) {
  font-family: "Helvetica Neue",HelveticaNeue,Helvetica,Arial,sans-serif !important;
}

.landing-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 720px;
}


.landing-page .logo-container {
  /* width: 6.25vh;
  height: 6.; */
  display: flex;
  justify-content: center;
  align-items: center;
}


.landing-page .logo-container img {
  width: 80px;
  height: 80px;
}

.landing-page .tagline-container {
  text-align: center;
  padding: 0 26px;

}

.landing-page .tagline-container h2 {
  font-weight: 400;
  font-size: 16px;
  margin: 10px 0 16px 0;
  line-height: 22px;
}

.landing-page .form-toggler {
  display: flex;
  flex-direction: column;
  /* padding: 5px; */
  width: 100%;
}

.landing-page .form-toggler button {
  width: 100%;
  height: 45px;
  color: white;
  font-weight: 600;
  text-transform: capitalize;
  border: none;
  margin-top: 12px;
  font-size: 16px;
  border-radius: 2px;
}

.landing-page button.landing-secondary {
  background: white;
  color: #444;
}

.landing-page button.landing-secondary:hover {
  background: white;
  color: #444;
}

.landing-page .toggler {
  text-align: center;
  font-size: 16px;
  width: 100%;
  margin-bottom: 7px;
  line-height: 17px;
}

.landing-page .toggler a {
  margin: 0 5px;
}

.landing-page .form {
  width: 100%;
}

.landing-page .form h3 {
  text-align: center;
  margin: 10px 0;
}

.landing-page .form .field,
.landing-page .form .field button {
  margin-bottom: 10px;
}

.landing-page .form .field .required {
  height: 44px;
  margin: 0;
}

.landing-page .form .input {
  display: inline-block;
}

.landing-page .form .field .ui.input input {
  width: 100%;
  border-radius: 2px;
  font-size: 16px;
  padding: 14px;
  height: 44px;
}


.landing-page .form .button {
  display: flex;
  justify-content: center;
  font-size: 16px;
  width: 100%;
  align-items: center;
}


.landing-page .form button {
  width: 100%;
  margin: 0;
  padding: 0 !important;
  border: none !important;
  height: 45px !important;
  font-size: 16px;
  border-radius: 2px !important;
}

.landing-page code {
  background-color: #262626;
  padding: 0 5px;
  font-family: 'Consolas', Courier, monospace !important;
  color: #4799eb;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  text-decoration: none;
  margin-top: -1px;
}


.landing-page .footer {
  margin-bottom: 5px;
  margin-left: auto;
  padding: 0 10px 0 10px;

}

.lpnav .icons-container a {
  color: white;
  font-size: 16px;
  margin: 0 10px;
}

.lpnav .icons-container, .lpnav .navbar-buttons {
  margin-right: 20px;
}

.lpnav .navbar-buttons .ui.button {
  font-size: 14px
}

.lpnav .navbar-buttons .ui.button.primary {
  background: rgba(0,0,0,.0941176);
}

.landing-page .legal-links {
  margin-left: auto;
}


.landing-page .legal-links a {
  color: white;
}

.landing-page .legal-links a:hover {
  text-decoration: underline;
}

.landing-page .width-limit-wrapper {
  width: 300px;
}

.landing-page .scroll-hint {
  height: 45px;
  background-color: #00cf35;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 45px;
  cursor: pointer;
  width: 100%;
}

.lpnav-wrapper {
  position: fixed;
  z-index: 9999999;
  width: 100%;
}

.lpnav {
  width: 100%;
  min-width: 320px;
  max-width: 1580px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto
}

.lpnav > a {
  display: flex;
  align-items: center;
  margin: 11px 0 6px 22px;
}

.lpnav .icp-name {
  margin-left: 16px;
  font-size: 16px;
  color: white;
  font-weight: bold;
  white-space: nowrap;
}

/* .landing-page .footer .photographer {
  font-size: 14px;
  display: flex;
  align-items: center;

}

.landing-page .footer .photographer span {
  font-weight: 500;
  padding: 0 5px;
}

.landing-page .footer .photographer img {
  margin-top: auto;
  width: 24px;
  height: 24px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  margin: 0 5px;
} */

.slider {
	position: relative;
  width: 100%;
  min-width: 320px;
	height: 100vh;
  overflow: hidden;
}

.slide {
  width: 100%;
  padding-top: 51px;
	height: 100%;
	position: absolute;
	overflow: hidden
}

.slide.hidden {
  top: -100%;
  visibility: hidden;
  transition: top 0;
}

.slide.previous {
	top: -100%;
}

.slide.current {
	top: 0;
}

.slide.next {
  top: 0;
  visibility: hidden;
}

.slide.animateIn,
.slide.animateOut {
	transition: top .7s cubic-bezier(.825,0,.5,1);
}

.slide.animateIn.previous,
.slide.animateIn.next {
	top: 0;
	visibility: visible;
}

.slide.animateOut.previous {
  top: 0;
  z-index: -2;
}

.slide.animateOut.next {
  top: -100%;
  z-index: 999;
  visibility: visible
}

.lp-slide-controller {
  position: fixed;
  height: fit-content;
  z-index: 2;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
}

.lp-slide-controller * {
  border-radius: 50%;
  box-shadow: inset 0 0 0 3px #fff;
  cursor: pointer;
  height: 16px;
  margin-bottom: 10px;
  opacity: .4;
  position: relative;
  width: 16px;
  box-sizing: border-box;
}

.lp-slide-controller *:hover {
  opacity: 1;
}

.lp-slide-controller .active {
  background: #fff;
  box-shadow: none;
  opacity: 1;
}

.slider h1 {
  font-size: 60px;
  line-height: 1;
  margin: 0 0 15px;
  font-weight: 400;
  font-family: Gibson, "Helvetica Neue",HelveticaNeue,Helvetica,Arial,sans-serif !important;
}

.slider p {
  font-size: 16px;
  line-height: 22.4px;
  font-weight: 400;
}

.slide.s2 {
  text-align: center;
  /* Background color slide 2 */
  background: #00cf35;
}

.slide.s2 img {
  height: 440px;
  margin: 0 auto -78px;
  overflow: visible;
  position: relative;
  box-sizing: border-box;
}

.slide.s2 .s2-wrapper {
  margin: auto;
  width: 700px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.slide.s3 {
  /* Background color slide 3 */
  background: #7c5cff;
  display: grid;
  grid-template-rows: auto 1fr;
}

.slide.s3 .s3-wrapper {
  margin-left: 180px;
  margin-top: 99px;
  margin-bottom: 20px;
  width: 500px;
  height: 100%;
  max-width: 100%;
}

.slide.s3 img {
  margin-top: 10px;
  margin-left: 180px;
}

.slide.s4 {
  display: table-cell;
  vertical-align: middle;
  /* Background color slide 4 */
  background: #001935;
}

.slide.s4 .s4-wrapper {
  position: absolute;
  height: fit-content;
  left: 50%;
  margin-left: 140px;
  width: 370px;
  height: 1488px;
  max-height: calc(100% - 110px);
  top: 110px;
}

.slide.s4 .images {
  left: 50%;
  margin-left: -440px;
  position: absolute;
  top: 110px;
  /* height: calc(98% - 120px); */
  width: 540px;
  transform-origin: top;
}

.slide.s4 img {
  width: 100%;
  margin-bottom: 10px;
}

.slide.s4 .centralize {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}


@media only screen and (min-width: 1600px) {
  .slide.s3 .s3-container, .slide.s3 img {
    margin-right: auto;
    margin-left: auto;
    width: 1260px;
  }
  .slide.s3 .s3-wrapper {
    margin-left: 0;
  }
}


@media only screen and (max-height: 700px), only screen and (max-width: 1100px) {
  .slide h1 {
    font-size: 42px;
  }

  .slide.s2 .s2-wrapper {
    width: 600px;
  }

  .slide.s2 img {
    height: 320px;
    margin-bottom: -50px 
  }

  .slide.s3 .s3-wrapper   {
    margin-left: 140px;
    margin-top: 69px;
  }

  .slide.s3 img {
    margin-left: 140px;
  }

  .slide.s4 .s4-wrapper {
    margin-left: 80px;
    height: 1041.6px;
  }

  .slide.s4 .images {
    transform: scale(.7);
  }
}

@media only screen and (max-height: 540px), only screen and (max-width: 900px) {
  .slide h1 {
      font-size: 34px;
  }

  .slide.s2 .s2-wrapper {
    width: 540px;
  }

  .slide.s2 img {
    height: 220px;
    margin-bottom: -30px;
  }

  .slide.s4 .s4-wrapper {
    margin-left: 50px;
    width: 250px;
    height: 744px;
  }

  .slide.s4 .images {
    transform: scale(.5);
    margin-left: -375px;
  }
}


@media only screen and (max-width: 540px) {
  .slide h1 {
    font-size: 6.296296296296296vw;
  }

  .slider p {
    font-size: 2.962962962962963vw;
    line-height: 4.148148148148148vw;
  }

  .slide.s2 .s2-wrapper {
    width: 70.0vw;
  }

  .slide.s2 img {
    height: 40.74074074074074vw;
  }

  .slide.s3 .s3-wrapper {
    width: 59.25925925925926vw;
    margin-left: 25.925925925925927vw;
  }

  .slide.s3 img {
    margin-left: 25.925925925925927vw;
  }

  .slide.s4 .s4-wrapper {
    width: 35vw;
    margin-left: 9.25925925925926vw;
    height: 110.22222222222224vw;
  }

  .slide.s4 .images {
    transform: none;
    width: 40vw;  
    margin-left: -35.18518518518518vw;
  }

  .lpnav .icp-name {
    display: none;
  }

  .lpnav .ui.button {
    padding: 10px;
  }

  .lp-slide-controller {
    position: fixed;
    z-index: 2;
    top: unset;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%) rotate(-90deg);
  }
}

@media only screen and (max-width: 375px) {
  .slide h1 {
    font-size: 23.611111111111107px;
  }

  .slider p {
    font-size: 11.11111111111111px;
    line-height: 15.555555555555554px;
  }

  .slide.s2 .s2-wrapper {
    width: 262.5px;
  }

  .slide.s2 img {
    height: 152.77777777777777px;
  }

  .slide.s3 .s3-wrapper {
    width: 222.22222222222223px;
  }
}

@media (max-height: 500px) {
  .lp-slide-controller {
    display: none;
  }
}