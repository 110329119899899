/*! CSS Used keyframes */
@-webkit-keyframes joinButtonArrowSvgGyration {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes joinButtonArrowSvgGyration {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.card {
  color: #b9c9da
}

.card p > a, .card p > span > a {
  color: white;
}

.card p > a:hover, .card p > span > a:hover {
  color: white;
  text-decoration: underline;
}

.card:hover {
  box-shadow: 0 0 16px #081421;
}

.card p {
  font-size: 17px;
}

.inline {
  display: inline;
}

.header .image {
  object-fit: cover;
}

.card .header .image:hover {
  /* box-shadow: 0 0 0 2px #fff; */
}

.card .center.mobile-only {
  padding-top: 10px;
}

.menu.secondary.pointing {
  border: 3px solid #1f364d;
  border-radius: 2px;
  font-size: 16px;
  padding-left: 4px;
  margin-right: 4px;
  width: 100%;
}

.menu.secondary.pointing .item {
  line-height: 24px;
}

.menu.secondary.pointing .item:hover {
  border-bottom-color: #385c80;
}

.menu.secondary.pointing .menu-buttons, .button {
  margin: 9px 9px 9px auto;
}

.header .content {
  padding-top: 5px;
}

.feed {
  margin: 32px 0 30px;
  display: grid;
  grid-template-columns: 1fr 220px;
  grid-gap: 22px;
  justify-content: stretch;
}

.sidebar {
  padding-bottom: 38px;
}

.sidebar section {
  width: 100%;
  margin-bottom: 38px;
}

.sidebar .title {
  color: #b3cce6;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  border: 3px solid #1f364d;
  border-radius: 2px;
  font-size: 16px;
  height: 58px;
  padding: 0 14px;
}

.sidebar .title * {
  margin: auto 0;
}

/* .sidebar .title:hover {
  color: #FFF;
  border-color: #385c80;
  
} */

.sidebar svg {
  margin-left: auto;
  height: 18px;
  width: 18px;
  fill: #314f6d;
  grid-column: 3;
}

.sidebar .site-info-row {
  padding: 6px 8px 6px 0;
  display: inline-flex;
}

.sidebar .site-info {
  display: inline-flex;
}

.sidebar .site-info i, .sidebar .site-info svg {
  width: 16px;
  color: #9cb3c9;
  fill: #9cb3c9;
  margin-right: 10px;
  font-size: 16px;
}

.sidebar .site-info:hover i, .sidebar .site-info:hover svg  {
  color: #4799eb;
  fill: #4799eb;
}

.sidebar .site-info span {
  color: #9cb3c9;
  font-size: 16px;
}

.sidebar .site-info:hover span {
  color: #FFF;
}

.sidebar .site-info-row .divider {
  margin: 0 6px;
  transform: translateY(1px);
}

.sidebar .end-links {
  display: flex;
  justify-content: space-between;
  padding-right: 18px;
  /* margin-bottom: */
}

.sidebar .end-links a {
  position: relative;
  color: #4799eb;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: color 160ms;
  align-self: flex-start;
  margin-top: 10px;
  margin-right: 13px;
}

.sidebar .end-links a:hover {
  color: #FFF;
}

.sidebar .end-links svg {
  height: 14px;
  width: 14px;
  fill: #4799eb;
  left: calc(100% + 6px);
  position: absolute;
  top: calc(50% - 6px);
  -webkit-transition: all 160ms;
  transition: all 160ms;
}

.sidebar .end-links a:hover svg {
  animation: joinButtonArrowSvgGyration 0.9s infinite;
  fill: #fff;
}

.sidebar .meetup {
  display: flex;
  margin-top: 16px;
}

.sidebar .meetup .date {
  align-items: center;
  border: 3px solid #1f364d;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: 46px;
  justify-content: center;
  margin-top: 4px;
  text-align: center;
  width: 44px;
}

.sidebar .meetup:hover .date {
  border-color: #385c80;
}

.sidebar .meetup .date .month {
  color: #b3cce6;
  font-size: 11px;
  font-weight: 600;
  line-height: 1em;
  text-transform: uppercase;
}

.sidebar .meetup .date .day {
  color: #4799eb;
  font-size: 15px;
  font-weight: 600;
  line-height: 1em;
  margin: 1px auto 0;
}

.sidebar .meetup .text {
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: calc(100% - 44px - 10px);
}

.sidebar .meetup .text .name {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  color: #fff;
  font-size: 16px;
  line-height: 1.25em;
}

.sidebar .meetup:hover .text .name {
  background-color: #1f364d;
}

.sidebar .meetup .text .location {
  color: #9cb3c9;
  font-size: 14px;
  margin-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar .meetup-chat {
  margin-top: 14px;
}

.sidebar .meetup-chat .country {
  font-size: 16px;
}

.sidebar .meetup-chat .city {
  margin-top: 2px;
  font-size: 14px;
  color: #9cb3c9;
}

.sidebar .meetup-chat .city a {
  color: #9cb3c9;
  white-space: nowrap;
  line-height: 22px;
}

.sidebar .meetup-chat .city a:hover {
  color: white;
  text-decoration: underline;
}

.sidebar .media {
  display: flex;
}

.sidebar .icon-links a {
  color: #9cb3c9;
  margin-right: 10px;
  font-size: 15px
}

.sidebar .icon-links a:hover {
  color: #1e70bf;
}

.feed .ui.card .header .content {
  padding-top: 0;
  margin-bottom: -3px;
}

.feed .ui.card .header .content a {
  font-size: 17px;
}

.feed .ui.card .sub.header {
  font-size: 15px;
  margin-top: 0;
}


@media (max-width: 767px) {

  .sidebar {
    display: none;
  }

  .feed {
    grid-template-columns: 1fr;
  }
}
