.form-template-wrapper {
  margin: 30px auto;
  max-width: 542px;
}


.form-template .ui.header {
  font-size: 18px;
  line-height: 32px;
  color: white;
  margin: 0 0 16px;
  font-weight: 600;
}

.form-template .ui.header.tiny {
  margin: 0px
}

.form-template .ui.header .content {
  padding-left: 0;
  padding-top: 0;
  font-weight: 500;
  font-size: 19px
}

.form-template .ui.header .image {
  object-fit: cover;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin: auto 20px auto 0;
}

.form-template .ui.header .sub.header {
  margin-top: -3px;
  width: fit-content;
}

.form-template .sub.header a {
  color: #4799eb;
  font-size: 14px;
  font-weight: 600;
}

.form-template .sub.header input {
  display: none;
}

.form-template .sub.header a:hover  {
  color: #5ea6ee;
  cursor: pointer;
}

.form-template .segment {
  background-color: #233b53;
  padding: 20px;
}

.form-template .form .field {
  margin-bottom: 20px;
}

.form-template .form .fields {
  margin-left: 0;
  margin-right: 0;
}

.form-template .form .field>label {
  font-size: 13px;
  color: white;
  margin-bottom: 10px;
}

.form-template .search {
  width: 100%;
}

.form-template .search .icon {
  display: none;
}


.form-template .labels {
  margin-bottom: 10px;
}

.form-template .ui.form textarea {
  font-size: 13px;
  padding: 10px;
}

.form-template .form .ui.input input {
  font-size: 13px;
  height: 35px;
  padding: 10px;
}

.form-template hr {
  border-bottom: none;
  border-color: #9cb3c9;
  margin: 20px -20px 10px;
}

.form-template .form .tiny.header {
  font-size: 14px;
  color: #9cb3c9;
}

.form-template.subscribe-page .segment {
  background: #274059;
}

.form-template.subscribe-page .secondary.button{
  border-color: #1f364d;
}

.form-template.subscribe-page .secondary.button:hover {
  background: #1f364d;
}

.form-template .form-section {
  margin-bottom: 27px;
}

.form-template label a {
   color: white;
   font-weight: 600;
}

.form-template .ui.checkbox.checked label,
.form-template .ui.checkbox input:focus:checked~label,
.form-template .ui.toggle.checkbox.checked label,
.form-template .ui.toggle.checkbox input:focus:checked~label {
  display: block;
    margin: 0 0 .28571429rem 0;
    color: #fff !important;
    font-size: .92857143em;
    font-weight: 600;
    text-transform: none;
}

.form-template .checkbox {
  margin-bottom: 15px;
}

.form-template .form .form-section .field:first-child>label {
  margin-top: 3px;
}

.form-template .form .form-section .field:not(:first-child)>label {
  margin-top: -8px;
}

.form-template .ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before {
  background: rgba(0,0,0,.15);
}

.form-template .ui.form .normalized-font {
  font-weight: 400;
}

.form-template .ui.form .extra-margin-bottom-15 {
  margin-bottom: 15px;
}

.form-template .bubbleInput.entity-label.ui.basic.label {
  font-size: 13px;
  padding-top: 7px;
  padding-bottom: 7px;
}