.applicant-social{
    padding-right: 20px;
}

.applications-page .ui.menu:not(.vertical) .item:hover{
    color: white;
}

.applications-page .ui.menu .active.item{
    background-color: #233b53 !important;
    color: white;
}

.applications-page .ui.menu:first-child{
    background-color: #0e2439   
}

.applications-page .ui.segment[class*="bottom attached"]:last-child{
    background-color: #0e2439;
    border: none;
    padding: 0;
} 