.meetup-page{
    margin-top: 30px;
    padding-left: 36px;
    padding-right: 36px;
}

.meetup-page h1{
    font-size: 18px;
    margin-bottom: 16px;
}

.meetup-page section {
    margin-bottom: 38px;
}

.meetup-page .title {
    color: #b3cce6;
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto;
    border: 3px solid #1f364d;
    border-radius: 2px;
    font-size: 16px;
    height: 58px;
    padding: 0 14px;
  }
  
  .meetup-page .title * {
    margin: auto 0;
  }
  
  .meetup-page svg {
    margin-left: auto;
    height: 18px;
    width: 18px;
    fill: #314f6d;
    grid-column: 3;
  }
  
  .meetup-page .site-info-row {
    padding: 6px 8px 6px 0;
    display: inline-flex;
  }
  
  .meetup-page .site-info {
    display: inline-flex;
  }
  
  .meetup-page .site-info i, .meetup-page .site-info svg {
    width: 16px;
    color: #9cb3c9;
    fill: #9cb3c9;
    margin-right: 10px;
    font-size: 16px;
  }
  
  .meetup-page .site-info:hover i, .meetup-page .site-info:hover svg  {
    color: #4799eb;
    fill: #4799eb;
  }
  
  .meetup-page .site-info span {
    color: #9cb3c9;
    font-size: 16px;
  }
  
  .meetup-page .site-info:hover span {
    color: #FFF;
  }
  
  .meetup-page .site-info-row .divider {
    margin: 0 6px;
    transform: translateY(1px);
  }
  
  .meetup-page .end-links {
    display: flex;
    justify-content: space-between;
    padding-right: 18px;
    margin-top: 13px;
  }
  
  .meetup-page .end-links a {
    position: relative;
    color: #4799eb;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    transition: color 160ms;
    align-self: flex-start;
    margin-top: 10px;
    margin-right: 13px;
  }
  
  .meetup-page .end-links a:hover {
    color: #FFF;
  }
  
  .meetup-page .end-links svg {
    height: 14px;
    width: 14px;
    fill: #4799eb;
    left: calc(100% + 6px);
    position: absolute;
    top: calc(50% - 6px);
    -webkit-transition: all 160ms;
    transition: all 160ms;
  }
  
  .meetup-page .end-links a:hover svg {
    animation: joinButtonArrowSvgGyration 0.9s infinite;
    fill: #fff;
  }
  
  .meetup-page .meetup {
    display: flex;
    margin-top: 16px;
  }
  
  .meetup-page .meetup .date {
    align-items: center;
    border: 3px solid #1f364d;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    height: 46px;
    justify-content: center;
    margin-top: 4px;
    text-align: center;
    width: 44px;
  }
  
  .meetup-page .meetup:hover .date {
    border-color: #385c80;
  }
  
  .meetup-page .meetup .date .month {
    color: #b3cce6;
    font-size: 11px;
    font-weight: 600;
    line-height: 1em;
    text-transform: uppercase;
  }
  
  .meetup-page .meetup .date .day {
    color: #4799eb;
    font-size: 15px;
    font-weight: 600;
    line-height: 1em;
    margin: 1px auto 0;
  }
  
  .meetup-page .meetup .text {
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width: calc(100% - 44px - 10px);
  }
  
  .meetup-page .meetup .text .name {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    color: #fff;
    font-size: 16px;
    line-height: 1.25em;
  }
  
  .meetup-page .meetup:hover .text .name {
    background-color: #1f364d;
  }
  
  .meetup-page .meetup .text .location {
    color: #9cb3c9;
    font-size: 14px;
    margin-top: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .meetup-page .meetup-chat {
    margin-top: 14px;
  }
  
  .meetup-page .meetup-chat .country {
    font-size: 16px;
  }
  
  .meetup-page .meetup-chat .city {
    margin-top: 2px;
    font-size: 14px;
    color: #9cb3c9;
  }
  
  .meetup-page .meetup-chat .city a {
    color: #9cb3c9;
    white-space: nowrap;
    line-height: 22px;
  }
  
  .meetup-page .meetup-chat .city a:hover {
    color: white;
    text-decoration: underline;
  }
  
  .meetup-page .media {
    display: flex;
  }
  
  .meetup-page .icon-links a {
    color: #9cb3c9;
    margin-right: 10px;
    font-size: 15px
  }
  
  .meetup-page .icon-links a:hover {
    color: #1e70bf;
  }
  