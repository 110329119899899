.searchbox {
  width: 100%;
  background-color: #1f364d;
  padding-bottom: 30px;
}

.searchbox .ui.input.wrapper input {
  background-color: inherit;
  color: white;
  border: none;
  font-size: 30px;
  padding: 0;
  height: 8vh;
  min-height: 75px;
  max-height: 120px;
  align-items: center;
  box-shadow: none;
}

.searchbox .ui.input.wrapper input:focus {
  box-shadow: none;
}

.searchbox .ui.input.wrapper input::placeholder {
  color: #6c8399;
}

.search-wrapper {
  margin-bottom: 30px;
}

.search-wrapper .container {
  max-width: 1000px !important;
}

.filterbox-wrapper {
  margin-top: -25px;
  background-color: #0e2439;
  border: 3px solid #1f364d;
  border-radius: 4px;
  margin-bottom: 20px;
}

.filterbox-wrapper .menu.secondary.pointing {
  overflow-x: auto;
  padding-bottom: 3px;
  margin-bottom: -3px;
  border: none;
  height: 52px;
}

.filterbox-wrapper .menu.secondary.pointing .item {
  padding: 0;
  margin-left: 9px;
  margin-right: 9px;
  height: 52px; 
}

.filterbox-wrapper .menu.secondary.pointing .item:last-child {
  padding-right: 13px;
}

.filterbox-wrapper .menu.secondary.pointing .item > .label {
  margin-left: 8px;
}

.filterbox-wrapper .menu::-webkit-scrollbar {
    height: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.filterbox-wrapper .menu .item .label {
  background-color: #1f364d;
  color: #b3cce6;
  border-radius: 4px
}

.filterbox-wrapper .menu .active.item .label {
  background-color: #4799eb;
  color: white;
}

.search-wrapper .card {
  margin-bottom: 20px;
}

.search-item {
  margin: -9px 1px;
  display: flex;
  flex-wrap: nowrap;
}

.search-item .left {
  display: grid;
  grid-template-columns: auto 1fr;
}

.search-item .right {
  margin-left: auto;
  display: block;
}

.search-item .image {
  object-fit: cover;
  /* box-shadow: rgba(0, 0, 0, 0.18) 0px 5px 15px 0px; */
  border-radius: 3px;
  margin: auto 20px auto 0;
}

.search-item .info .name {
  font-size: 18px;
  font-weight: 600;
}

.search-item .location a {
  color: #b9c9da;
  font-size: 14px;
}

.search-item .location a:hover {
  color: white;
}

.search-item .headline {
  color: white;
}

.search-item .location {
  margin-top: 5px;  
}

.people-search-item .location {
  margin-top: 3px
}

.people-filter-box {
  display: flex;
  border: 3px solid #1f364d;
  border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 20px;
  padding-left: 4px;
}

.people-filter-box .dropdown-toggle {
  color: #9cb3c9;
  display: flex;
  padding: 0;
  height: 46px;
  margin: 0 9px;
  font-size: 16px;
  white-space: nowrap;;
  align-items: center;
}

.people-filter-box .dropdown-toggle svg {
  fill: #9cb3c9;
  width: 10px;
  height: 10px;
  margin: auto 0 auto 6px;
}

.people-filter-box .dropdown-toggle:hover {
  color: white;
}

.people-filter-box .dropdown-toggle:hover svg {
  fill: white;
}

.people-filter-box .search-count {
  background: #1f364d;
  padding: 4px 6px;
  margin: 0 0 0 8px;
  font-weight: 400;
  color: #b3cce6;
  font-size: 13px;
  font-weight: 600;
}

.people-filter-box .search-count:empty {
  display: none;
}

.people-filter-box .clear-wrapper {
  margin: auto 10px auto auto;
}

.people-filter-box .clear-wrapper .button {
  margin: auto;
}

.people-filter .ui.input input {
  width: 100%;
}

.ui.search-popup.popup {
  padding: 25px;
}

.people-filter .checkbox-wrapper {
  padding: 10px 5px 10px;
}

.people-filter .mini-menu > .button {
  display: block;
  margin-left: auto;
}

.people-filter .mini-menu li {
  list-style: none;
  margin-bottom: 10px;
}


@media (max-width: 767px) {
  .search-item .info .name {
    font-size: 15px;
  }

  .search-item {
    display: block;
  }

  .search-item .image {
    width: 35px;
    height: 35px;
  }

  .search-item .right {
    background-color: #0e2439;
    margin: 10px -22px -12px;
    text-align: center;
  }


  .search-item .button {
    margin: 20px auto 15px;
  }

  .search-item .location {
    margin-top: 2px;  
  }
  
  .people-search-item .location {
    margin-top: 1px
  }
}

@media (max-width: 499px) {
  .searchbox .ui.input.wrapper input {
    font-size: 20px;
    max-height: 80px;
    min-height: 50px;
  } 
}
