.ui.progress.onboarding-progress-bar {
  margin: 0;
}

.ui.progress.onboarding-progress-bar .bar {
  background-color: #2185d0;
  height: 7px;
  border-radius: 0;
}

.onboarding-wrapper,
.onboarding-5-container,
.onboarding-4-container {
  margin-top: 50px;
}

.onboarding-heading {
  line-height: 1.5;
  color: white;
  font-size: 40px;
}

.onboarding-sub-heading {
  font-size: 18px;
  margin: 12px 10px 28px 0;
  line-height: 25px;
}

.facebook .kep-login-facebook.metro {
  margin-top: 2vw;
  padding: 8px 20px;
}

.onboarding-5-container .profile-form,
.onboarding-form-wrapper.form {
  width: 494px;
}

.onboarding-5-container .onboarding-form-wrapper {
  max-width: 329px;
}

.onboarding-5-container .onboarding-form-wrapper .margin  {
  margin: 14px 0;
}

.onboarding-5-container .onboarding-form-wrapper .margin .input {
  width: 100%;
}

.onboarding-5-container .onboarding-form-wrapper .margin .input > input:active,
.onboarding-5-container .onboarding-form-wrapper .margin .input > input:focus {
  color: #000;
}

.onboarding-4-container .form-wrapper {
  max-width: 596px;
}

.onboarding-4-container .view-change {
  margin-top: -10px;
  margin-bottom: 20px;
}

.onboarding-4-container .view-change .radio label::before,
.onboarding-4-container .view-change .radio input:checked~label:before,
.onboarding-4-container .view-change .radio input:focus~label:before,
.onboarding-4-container .view-change .radio input:hover~label:before {
  border-color: #1f364d;
  border-width: 2px;
}

.onboarding-4-container .view-change .radio:first-child {
  margin-right: 10px;
}

.onboarding-5-container .swa.ui.search .ui.input .prompt,
.onboarding-4-container .swa.ui.search .ui.input .prompt {
  width: 100%
}

.onboarding-4-container .school-years,
.onboarding-4-container .work-years {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px
}

.onboarding-4-container .school-years .field .dropdown,
.onboarding-4-container .work-years .field .dropdown {
  min-width: 0;
}

.onboarding-4-container .pointer {
  margin-bottom: 10px;
}

.onboarding-4-container .work-years .field .dropdown:nth-child(2) {
  margin-bottom: 10px;
}

.onboarding-4-container .work-years .present {
  margin: auto 0;
    font-size: 14px;
    font-weight: 600;
    color: white;
}

.onboarding-5-container .skill-form .swa {
  margin-bottom: 10px;
}

.onboarding-5-container .skill-form .entities-wrapper:empty {
  margin-top: 0;
  margin-bottom: -10px
}

.onboarding-5-container .ui.form .field.facebook {
  margin-bottom: 20px
}

.onboarding-5-container .entities-wrapper .entity-label.ui.label.basic {
  border-color: #0e2439;
}

.onboarding-5-container .entities-wrapper .entity-label.ui.label.basic .remove span {
  background-color: #0e2439;
}

.onboarding-6-container .column {
  margin-top: 50px;
}

.onboarding-6-container h1 {
  font-size: 34px;
  font-weight: 700;
}

.onboarding-6-container .sub-heading {
  font-size: 16px;
  line-height: 1.8;
}

.onboarding-6-container .image {
  object-fit: cover;
  margin: 20px auto;
}

.onboarding-4-container,
.onboarding-5-container,
.onboarding-6-container {
  margin-bottom: 30px;
}

.onboarding-column .facebook .kep-login-facebook.metro {
  margin-top: 0;
  text-transform: none;
  background-color: #3C5A99;
  border-color: #3C5A99;
}


.onboarding-column .ui.form .field {
  margin-bottom: 28px
}

@media (max-width: 767px) {
  .onboarding-heading {
    font-size: 30px;
  }

  .onboarding-sub-heading {
    font-size: 16px;
  }

  .onboarding-form-wrapper.ui.form {
    width: 100%;
    max-width: 405px;
  }

}
