.settings .forgotten-password{
  margin: -12px 0 20px;
}

.settings .verify-button {
  margin-top: -12px
}

.forgotten-password-btn-wrapper {
  height: 34px;
}

.settings .forgotten-password .forgotten-password-btn {
  font-family: 'Source Sans Pro';
  border: 2px solid transparent;
  color: #4799eb;
  height: 34px;
  font-weight: 600;
  cursor: pointer;
}

.settings .forgotten-password .forgotten-password-btn:hover {
  color: #5ea6ee;
}


.settings .forgotten-password h5.reset-button {
  color: #9cb3c9;
  height: 34px;
}

.settings .forgotten-password .loader {
  margin: 0 0 9.875px !important;
}
