.navbar {
  height: 56px;
  background-color: #1f364d;
  color: #1f364d;
}

.navbar .ui.container {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.navbar .icp-logo {
  display: flex;
  margin: auto 0;
}

.navbar .icp-logo .icp-name {
  margin: 2px 0 0 15px;
  font-size: 15px;
  color: white;
  font-weight: bold;
}

.navbar .right, .navbar .nav-menu {
  display: flex;
}

.navbar .right>a,
.navbar .nav-menu a,
.navbar .nav-menu button {
  margin: auto 0 auto 22px;
  font-size: 15px;
  font-weight: 600;
  color: #9cb3c9;
  transition: color 140ms, fill 140ms;
}

.navbar .right>a:hover,
.navbar .nav-menu a:hover {
  color: #FFF;
}

.navbar .nav-menu a svg {
  height: 11px;
  width: 11px;
  fill: #9cb3c9;
  margin-left: 5px;
  transform: translateY(2px);
  transition: color 140ms, fill 140ms;
}

.navbar .nav-menu .menu-icon {
  height: 18px;
  width: 18px;
  fill: #9cb3c9;
  margin: auto;
  transition: fill 140ms;
}

.navbar .nav-menu .menu-icon:hover {
  fill: #FFF;
}

.navbar .nav-menu a.search-icon svg {
  width: 18px;
  height: 18px;
  margin: 0;
  transition: fill 140ms;
}

.navbar .nav-menu a:hover svg {
  fill: #FFF
}

.navbar .profile-img {
  margin: auto 0 auto 22px;
}

.navbar .profile-img {
  object-fit: cover;
  border-radius: 50%;
}

.navbar .profile-img:hover {
  cursor: pointer;
  /* box-shadow: 0 0 0 2px #fff; */
}

.profile-popup {
  display: flex;
  flex-direction: column;
  margin: -0.833em -1em;
  width: 200px;
}

.profile-popup a {
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 600;
  color: #9cb3c9;
}

.profile-popup a:hover {
  background: #4799eb;
  color: white;
}

.mobile-popup header {
  align-items: center;
  background-color: #2c4763;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  margin-top: -8px;
  margin-bottom: 8px;
  padding: 16px;
}

.mobile-popup header a {
  align-items: center;
  display: flex;
}

.mobile-popup header a .logo {
  flex: 0 0 auto;
  margin-right: 12px;
  height: 24px;
}

.mobile-popup header a .name {
  text-transform: capitalize;
  font-size: 15px;
  color: white;
  margin-right: 12px;
  font-weight: bold;
}

.mobile-popup header .close {
  height: 16px;
  width: 16px;
  position: absolute;
  right: 12px;
  top: auto;
  animation: fadeIn 0.2s;
  cursor: pointer;
  fill: #fff;
  transition: all 0.2s;
}

.mobile-popup header .close:hover {
  fill: #e6375a;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.more-popup,
.mobile-popup {
  margin: -0.833em -1em;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  max-width: 100vw;
  width: max-content;
  background-color: #274059;
  box-shadow: 0 0 48px #0c1f31;
  overflow: auto;
}

.wrapper-mobile-popup,
.mobile-popup {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  margin: 0 !important;
  height: 100%;
  width: 100vw;
  max-width: 100vw !important;
}

.more-popup a,
.mobile-popup>a {
  transition: all 120ms;
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 2px;
  grid-template-areas:
    'icon    label   '
    'icon description'
  ;
  grid-template-columns: 50px 1fr;
  padding: 10px 16px;
  position: relative;
}

.more-popup a::before,
.mobile-popup>a::before {
  background-color: #4799eb;
  transition: all 120ms;
  content: '';
  height: 50px;
  right: calc(100% - 16px - 25px);
  position: absolute;
  top: calc(50% - 25px);
  width: 0;
}

.more-popup a .icon,
.mobile-popup>a .icon {
  background-color: #4799eb;
  grid-area: icon;
  height: 50px;
  width: 50px;
  align-items: center;
  align-self: center;
  border-radius: 50%;
  display: flex;
  fill: #fff;
  justify-content: center;
  transition: all 120ms;
}

.more-popup a .icon svg,
.mobile-popup>a .icon svg {
  height: 25px;
  width: 25px;
}

.more-popup a .label,
.mobile-popup>a .label {
  grid-area: label;
  display: flex;
  color: #9cb3c9;
  fill: #4799eb;
  align-self: end;
  font-size: 15px;
  font-weight: 600;
  grid-area: label;
  justify-self: start;
  position: relative;
  text-transform: capitalize;
}

.more-popup a .label svg,
.mobile-popup>a .label svg {
  height: 13px;
  width: 13px;
  left: calc(100% + 6px);
  opacity: 0;
  position: absolute;
  top: calc(50% - 7px);
  transition: all 160ms;
}

.more-popup a .description,
.mobile-popup>a .description {
  grid-area: description;
  align-self: start;
  font-size: 15px;
  color: #d9e6f2;
}

.more-popup a:hover,
.mobile-popup>a:hover {
  background-color: #2c4763;
}

.more-popup a:hover::before,
.mobile-popup>a:hover::before {
  width: 41px;
}

.more-popup a:hover .icon,
.mobile-popup>a:hover .icon {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.more-popup a:hover .label,
.mobile-popup>a:hover .label {
  filter: brightness(1.35);
}

.more-popup a:hover .label svg,
.mobile-popup>a:hover .label svg {
  animation: joinButtonArrowSvgGyration 0.9s infinite;
  opacity: 1;
}

.more-popup a:hover::before,
.more-popup a:hover .icon,
.mobile-popup>a:hover::before,
.mobile-popup>a:hover .icon {
  filter: brightness(1.2);
}

.mobile-popup {
  min-width: 320px;
}

/*! CSS Used keyframes */
@-webkit-keyframes joinButtonArrowSvgGyration {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes joinButtonArrowSvgGyration {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
